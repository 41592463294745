<template>
  <b-row class="section-spacing positioning profile-section">
    <b-col class="text-light">
      <h3 class="text-light mb-3 font-weight-lighter tab-title section-heading">
        {{ section.name }}
        <!--<b-button
          v-if="section.comments.length > 0"
          variant="link"
          class="float-right text-light show-all-btn"
          :to="{
            name: 'SongProfileList',
            params: { username: song.username, id: song.id, list: 'comments' },
          }"
          >Show All</b-button
        >-->
      </h3>
      <div class="border-gradient"></div>

      <div v-if="section.comments.length > 0" class="c-comments">
        <p
          class="comment-des comments-component"
          v-for="(comment, i) in section.comments"
          :key="i"
        >
          "{{ comment.comment }}"
        </p>
      </div>
      <p v-else class="comment-des py-2 pb-3">No comments to show yet</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SongCommentsSection",
  props: ["section", "song"],
};
</script>

<style>
.comment-des.comments-component {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 1.5em;
  color: #9b9b9b;
}

.comment-des.comments-component:last-child {
  margin-bottom: 0px !important;
}

@media (min-width: 576px) {
  .comment-des.comments-component {
    margin-bottom: 0.6em;
  }
}
</style>
