<template>
  <b-row class="pb-4 positioning profile-section pb-0 pb-md-3">
    <b-col>
      <h3 class="text-light mb-3 font-weight-lighter tab-title section-heading">
        {{ section.name }}
      </h3>
      <div class="border-gradient"></div>

      <div
        v-if="
          section.back_cover_image ||
            section.player_extra_image ||
            section.player_extra_image_2 ||
            section.player_extra_image_3 ||
            section.player_extra_image_4
        "
        class="c-covers-desktop overflow-auto"
      >
        <b-row class="row-covers">
          <div
            v-if="section.back_cover_image"
            class="c-secondary-backcover"
            :style="{
              backgroundImage: `url(${getImagePath(section.back_cover_image, {width: 250, height: 250})})`,
            }"
          ></div>
          <div
            v-if="section.player_extra_image"
            class="c-secondary-backcover"
            :style="{
              backgroundImage: `url(${getImagePath(
                section.player_extra_image, {width: 250, height: 250}
              )})`,
            }"
          ></div>
          <div
            v-if="section.player_extra_image_2"
            class="c-secondary-backcover"
            :style="{
              backgroundImage: `url(${getImagePath(
                section.player_extra_image_2, {width: 250, height: 250}
              )})`,
            }"
          ></div>
          <div
            v-if="section.player_extra_image_3"
            class="c-secondary-backcover"
            :style="{
              backgroundImage: `url(${getImagePath(
                section.player_extra_image_3, {width: 250, height: 250}
              )})`,
            }"
          ></div>
          <div
            v-if="section.player_extra_image_4"
            class="c-secondary-backcover"
            :style="{
              backgroundImage: `url(${getImagePath(
                section.player_extra_image_4, {width: 250, height: 250}
              )})`,
            }"
          ></div>
        </b-row>
      </div>

      <b-row
        v-if="
          !section.back_cover_image &&
            !section.player_extra_image &&
            !section.player_extra_image_2 &&
            !section.player_extra_image_3 &&
            !section.player_extra_image_4
        "
      >
        <b-col cols="12">
          <p class="comment-des py-2 pb-3 mb-0">
            Nothing to show yet
          </p>
        </b-col>
      </b-row>

      <b-row class="c-covers-mobile">
        <b-col cols="6">
          <img
            v-if="section.back_cover_image"
            width="100%"
            height="100%"
            :src="section.back_cover_image"
          />
        </b-col>
        <b-col cols="6">
          <b-row cols="12" class="c-secondary-images">
            <b-col cols="6" class="pl-0 covers-column">
              <div
                v-if="section.player_extra_image"
                class="c-secondary-backcover"
                :style="{
                  backgroundImage: `url(${getImagePath(
                    section.player_extra_image, {width: 250, height: 250}
                  )})`,
                }"
              ></div>
              <div
                v-if="section.player_extra_image_2"
                class="c-secondary-backcover"
                :style="{
                  backgroundImage: `url(${getImagePath(
                    section.player_extra_image_2, {width: 250, height: 250}
                  )})`,
                }"
              ></div>
            </b-col>
            <b-col cols="6" class="pl-0 covers-column">
              <div
                v-if="section.player_extra_image_3"
                class="c-secondary-backcover"
                :style="{
                  backgroundImage: `url(${getImagePath(
                    section.player_extra_image_3, {width: 250, height: 250}
                  )})`,
                }"
              ></div>
              <div
                v-if="section.player_extra_image_4"
                class="c-secondary-backcover"
                :style="{
                  backgroundImage: `url(${getImagePath(
                    section.player_extra_image_4, {width: 250, height: 250}
                  )})`,
                }"
              ></div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SongBackCoverSection",
  props: ["section"],
};
</script>

<style>
.c-secondary-backcover {
  height: 90%;
  width: 100%;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-covers-desktop {
  display: flex;
}

.row-covers {
  display: inline-flex;
  flex-wrap: nowrap;
}

@media (min-width: 576px) {
  .covers-column {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    display: flex;
  }

  .c-covers-mobile {
    display: none;
  }

  .c-secondary-backcover {
    width: 160px;
    height: 160px;
    margin-right: 20px;
  }

  .c-secondary-backcover.under-image {
    margin-top: 10px;
  }

  .overflow-auto::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 991.99px) {
  .c-secondary-backcover {
    width: 250px;
    height: 250px;
    margin-right: 30px;
  }
}

@media (max-width: 1200px) {
  .c-covers-desktop {
    margin-top: 40px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .c-secondary-backcover {
    width: 200px;
    height: 200px;
    margin-right: 20px;
  }
}

@media (max-width: 575px) {
  .covers-column {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    display: flex;
  }

  .c-secondary-backcover:first-child {
    margin-bottom: 10px;
  }

  .c-covers-mobile {
    display: flex;
  }

  .c-covers-desktop {
    display: none;
  }

  .c-secondary-backcover.under-image {
    margin-top: 10px;
  }

  .c-secondary-images {
    height: 100%;
  }
}
</style>
