var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"pb-4 positioning profile-section pb-0 pb-md-3"},[_c('b-col',[_c('h3',{staticClass:"text-light mb-3 font-weight-lighter tab-title section-heading"},[_vm._v(" "+_vm._s(_vm.section.name)+" ")]),_c('div',{staticClass:"border-gradient"}),(
        _vm.section.back_cover_image ||
          _vm.section.player_extra_image ||
          _vm.section.player_extra_image_2 ||
          _vm.section.player_extra_image_3 ||
          _vm.section.player_extra_image_4
      )?_c('div',{staticClass:"c-covers-desktop overflow-auto"},[_c('b-row',{staticClass:"row-covers"},[(_vm.section.back_cover_image)?_c('div',{staticClass:"c-secondary-backcover",style:({
            backgroundImage: ("url(" + (_vm.getImagePath(_vm.section.back_cover_image, {width: 250, height: 250})) + ")"),
          })}):_vm._e(),(_vm.section.player_extra_image)?_c('div',{staticClass:"c-secondary-backcover",style:({
            backgroundImage: ("url(" + (_vm.getImagePath(
              _vm.section.player_extra_image, {width: 250, height: 250}
            )) + ")"),
          })}):_vm._e(),(_vm.section.player_extra_image_2)?_c('div',{staticClass:"c-secondary-backcover",style:({
            backgroundImage: ("url(" + (_vm.getImagePath(
              _vm.section.player_extra_image_2, {width: 250, height: 250}
            )) + ")"),
          })}):_vm._e(),(_vm.section.player_extra_image_3)?_c('div',{staticClass:"c-secondary-backcover",style:({
            backgroundImage: ("url(" + (_vm.getImagePath(
              _vm.section.player_extra_image_3, {width: 250, height: 250}
            )) + ")"),
          })}):_vm._e(),(_vm.section.player_extra_image_4)?_c('div',{staticClass:"c-secondary-backcover",style:({
            backgroundImage: ("url(" + (_vm.getImagePath(
              _vm.section.player_extra_image_4, {width: 250, height: 250}
            )) + ")"),
          })}):_vm._e()])],1):_vm._e(),(
        !_vm.section.back_cover_image &&
          !_vm.section.player_extra_image &&
          !_vm.section.player_extra_image_2 &&
          !_vm.section.player_extra_image_3 &&
          !_vm.section.player_extra_image_4
      )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"comment-des py-2 pb-3 mb-0"},[_vm._v(" Nothing to show yet ")])])],1):_vm._e(),_c('b-row',{staticClass:"c-covers-mobile"},[_c('b-col',{attrs:{"cols":"6"}},[(_vm.section.back_cover_image)?_c('img',{attrs:{"width":"100%","height":"100%","src":_vm.section.back_cover_image}}):_vm._e()]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',{staticClass:"c-secondary-images",attrs:{"cols":"12"}},[_c('b-col',{staticClass:"pl-0 covers-column",attrs:{"cols":"6"}},[(_vm.section.player_extra_image)?_c('div',{staticClass:"c-secondary-backcover",style:({
                backgroundImage: ("url(" + (_vm.getImagePath(
                  _vm.section.player_extra_image, {width: 250, height: 250}
                )) + ")"),
              })}):_vm._e(),(_vm.section.player_extra_image_2)?_c('div',{staticClass:"c-secondary-backcover",style:({
                backgroundImage: ("url(" + (_vm.getImagePath(
                  _vm.section.player_extra_image_2, {width: 250, height: 250}
                )) + ")"),
              })}):_vm._e()]),_c('b-col',{staticClass:"pl-0 covers-column",attrs:{"cols":"6"}},[(_vm.section.player_extra_image_3)?_c('div',{staticClass:"c-secondary-backcover",style:({
                backgroundImage: ("url(" + (_vm.getImagePath(
                  _vm.section.player_extra_image_3, {width: 250, height: 250}
                )) + ")"),
              })}):_vm._e(),(_vm.section.player_extra_image_4)?_c('div',{staticClass:"c-secondary-backcover",style:({
                backgroundImage: ("url(" + (_vm.getImagePath(
                  _vm.section.player_extra_image_4, {width: 250, height: 250}
                )) + ")"),
              })}):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }