<template>
  <b-row
    v-if="section.songs_by_artist.length > 0"
    style=""
    class="pb-4 positioning c-most-listened profile-section pb-0 pb-md-3"
  >
    <b-col cols="12">
      <h3
        class="text-light mb-3 font-weight-lighter tab-title section-heading"
      >
        More from this Artist
        <b-button
          variant="link"
          class="float-right text-light show-all-btn font-weight-lighter "
          @click="show_all_link()"
          >Show All</b-button
        >
      </h3>
      <div class="border-gradient"></div>
    </b-col>

    <b-col>
      <b-row id="most-popular-songs" class="overflow-auto ">
        <div
          id="c-song-from-artist"
          class="card-carousel-cards c-most-popular-songs"
        >
          <b-col
            v-for="song in section.songs_by_artist"
            :key="song.id"
            class="text-center pr-0 c-most-popular-songs__song"
          >
            <img
              v-if="song.chipin_plus && song.locked"
              src="../../../assets/chipin+-locked-light.svg"
              class="song-locked"
              height="30"
              alt="Logo"
            />
            <player
              v-if="isOwn(request_user_id, song.artist_id)"
              v-bind:image="song.song_image"
              v-bind:file="song.song_url"
              v-bind:id="song.id"
              v-bind:title="song.song_name"
              v-bind:username="song.artist_name"
              v-bind:queue="section.songs_by_artist"
            ></player>
            <player
              v-else
              v-bind:chipin_plus="song.chipin_plus"
              v-bind:locked="song.locked"
              v-bind:image="song.song_image"
              v-bind:file="song.song_url"
              v-bind:id="song.id"
              v-bind:title="song.song_name"
              v-bind:username="song.artist_name"
              v-bind:artist_id="song.artist_id"
              v-bind:queue="section.songs_by_artist"
            ></player>
            <b-button
              v-if="song.retrak"
              pill
              size="sm"
              variant="warning"
              class="text-uppercase text-light text-small py-0"
              style="position: relative; top: -24px;"
              >Re-Track</b-button
            >

            <img
              src="../../../assets/trackd-icon-dark.svg"
              class="align-top top-arrow mt-2 mb-1 c-arrow-up-disk"
              alt="Logo"
            />

            <p
              class="text-light pb-0 mb-0 font-weight-light small l-popular-artist-song-description"
            >
              <router-link
                class="text-light popular-artist-song"
                :to="{
                  name: 'SongProfileID',
                  params: { username: song.artist_name, id: song.id },
                }"
                v-html="elipsis(song.song_name, 18)"
              ></router-link>
              <br />
              <span class="popular-artist-name">{{ song.artist_name }}</span>
            </p>
          </b-col>

          <div class="pl-4 col">
            <b-btn id="song-more-btn" class="song-more-btn">
              <div>
                <img src="../../../assets/library_show_all_icon.png" />
                <p class="mt-1 font-weight-light">More</p>
              </div>
            </b-btn>
          </div>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import player from "../../player.vue";
import { mapGetters } from "vuex";

export default {
  name: "SongSongSection",
  props: ["section", "song"],
  components: {
    player,
  },
  methods: {
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    show_all_link() {

      if (this.isAuthenticated()) {
        this.$router.push({
            name: 'SongProfileList',
            params: {
              username: this.song.username,
              id: this.song.user_id,
              list: 'songs_by_artist',
            },
          })

      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
  }
};
</script>

<style scoped>
.c-collab-badge {
  margin-left: 1rem;
}

.c-artist-header__strap-line {
  margin-top: 1rem;
}

.l-featured-songs {
  margin-top: 1.5rem;
}

.artist-profile {
  margin-top: 0.5rem;
  text-align: left;
}

.c-artist-photo {
  height: 250px;
  width: 250px;
}

.c-chipin-description {
  font-size: 1.5em;
}

.c-artist-information {
  font-size: 1.13em;
}

.c-featured-song {
  display: flex;
  width: 840px;
  min-width: 720px;
}

.c-most-popular-songs {
  margin-top: 5px;
}

.c-most-popular-songs__song {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-most-popular-songs__song:not(:first-of-type) {
  margin-left: 40px;
}

.social-profiles {
  margin-top: 2rem;
}

.popular-artist-song {
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-grey {
  color: #9b9b9b;
}

.artist-container {
  padding: 50px;
  padding-top: 18px;
}

.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.displayed-on-desktop {
  display: block;
}

.l-social-and-data.displayed-on-desktop {
  display: flex;
}

.photo-border {
  border-radius: 50%;
  border: 5px solid #000;
  box-sizing: border-box;
  margin-top: -150px;
  border: 3px solid white;
}

.border-success-2 {
  border: 2px solid #81cd5c !important;
  color: #81cd5c !important;
  font-size: 14px;
  line-height: 25px;
}

.border-danger-2 {
  border: 2px solid #e76e66 !important;
  color: #e76e66;
  font-size: 14px;
  line-height: 25px;
}

.btn-square {
  border-radius: 0;
}

.bw-2 {
  border-width: 2px;
}

.chipin-support-title {
  font-size: 2.25em;
}

.chipin-text {
  position: relative;
  top: -17px;
  font-size: 1.5em;
}

.stats-number {
  font-weight: 200;
  font-size: 48px;
  line-height: 21px;
}

.section-title {
  font-size: 2.2em;
}

.c-chipin-copyright__title {
  font-size: 21.5em;
}

.text-small {
  font-size: 0.6em;
}

.text-smaller {
  font-size: 18px;
  line-height: 21px;
}

.pb-4a,
.py-4a {
  padding-bottom: 4.5rem !important;
}

.pt-4a,
.py-4a {
  padding-top: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.py-6 {
  padding-top: 6rem !important;
}

.py-8 {
  padding-bottom: 8rem !important;
}

.py-8 {
  padding-top: 8rem !important;
}

.border-gradient {
  border-top: 2px solid #fff;
  border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0.1)) 1 40%;
  border-image-slice: 1;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 100%;
  margin-bottom: 2em;
  margin-top: 0.8em;
}

.more-btn {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 30px;
  margin-top: 25px;
  opacity: 0.7;
}

.btn-left {
  float: left;
}

.btn-dots {
  padding: 0px;
  margin-right: 30px;
  /* margin-top: 25px; */
  float: right;
}

.c-artist-header__name {
  font-size: 80px;
  line-height: 36px;
  margin-bottom: 2%;
}

.user-type {
  font-size: 36px;
  line-height: 36px;
}

.unfollow-btn {
  background: transparent;
  border: 2px solid white;
  border-radius: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  height: 42px;
  width: 140px;
}

.follow-btn {
  height: 42px;
  width: 140px;
}

.c-comment-icon {
  width: 40px;
  margin-top: 0px;
}

.support-img {
  height: 80px;
}

.follow-group {
  position: absolute;
  bottom: 18px;
  right: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
}

.stats-section {
  margin-top: 2.5em;
  justify-content: space-between;
}

.c-profile-badges {
  height: 30px;
  width: 140px;
}

.pill-badge {
  width: 180px;
  height: 30px;
  font-size: 14px;
}

.c-artist-header {
  padding-left: 35px;
  padding-right: 35px;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-location {
  font-weight: normal;
  color: #9b9b9b;
  display: inline-flex;
  align-items: center;
}

.user-overview {
  font-weight: normal;
  color: #9b9b9b;
  display: inline-flex;
  align-items: center;
}

.user-bio {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.social-bar {
  background-color: red;
}

.chipin-support {
  display: inline-flex;
  margin-top: 1em;
}

.genre-box {
  height: 50px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  font-size: 22px;
  line-height: 21px;
  padding: 0 40px 1px 40px;
}

.song-name {
  font-size: 24px;
  line-height: 23px;
}

.artist-name {
  font-weight: 200;
  font-size: 22px;
}

.song-btns {
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
}

.song-des {
  font-weight: 300;
  margin-bottom: 0;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  height: 3em; /* Fallback for non-webkit */
  font-size: 1.5em;
  line-height: 1.5em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-header {
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
}

.feature-left {
  width: 70%;
}

.feature-left p {
  width: 100%;
}

.likes-count {
  font-weight: normal;
  font-size: 24px;
  line-height: 21px;
}

.show-all-btn {
  font-weight: 300;
  font-size: 20px;
  margin-top: 0.5em;
}

.recent-text {
  font-weight: 300;
  font-size: 2.01em;
  line-height: 21px;
}

.artist-info {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.152972px;
}

.comment-des {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 1.5em;
}

chipin-link {
  text-decoration: underline;
}

.feature-div {
  display: inline-block;
  width: 100%;
}

.genre-badge {
  display: none;
}

.song-card {
  padding: 10px 0;
  margin-bottom: 25px;
}

.song-card:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
}
.popular-artist-name {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #9b9b9b;
}

.song-locked {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 100;
}
.support-gradient {
  display: none;
}
.tap-mobile {
  display: none;
}
.rtcl-btns {
  background-color: #f2b471;
  border-radius: 20px !important;
  padding-top: 5px;
}
.view-song-btn {
  display: none;
  width: 150px;
}
.card-carousel-cards {
  display: flex;
}
.overflow-auto::-webkit-scrollbar {
  display: none;
}
.song-more-btn {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-left: 15px;
  border: 3px solid #4a4a4a;
  background: transparent;
  display: none;
  align-items: center;
  justify-content: center;
}

.song-more-btn img {
  height: 50px;
}

.song-more-btn > div > p {
  margin-bottom: 0;
  color: #9b9b9b;
}
.displayed-on-mobile {
  display: none;
}
.top-arrow {
  margin: auto;
  margin-bottom: 10px;
}

.l-social-and-data {
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.c-most-listened {
  margin-top: 3rem;
}

.c-arrow-up-disk {
  height: 21px;
}

.c-song-indicators {
  display: inline-flex;
  align-items: center;
}

.more-icons.heart {
  margin-right: 24px;
}

.btn-18 {
  margin-right: 5px;
  color: #9b9b9b;
  border-color: #9b9b9b;
  padding-left: 5px;
  padding-right: 5px;
}

.l-desktop-genres {
  display: block;
}

.l-mobile-genres {
  display: none;
}

.l-comments-section {
  padding-bottom: 3rem;
}

.l-hall-of-fame {
  margin-bottom: -13vh;
}

.c-comment-icon {
  margin-right: 1rem;
}

.c-artist-bio {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.l-popular-artist-song-description {
  position: relative;
  top: -8px;
  line-height: 10px;
}

.l-wall-of-fame {
  justify-content: space-between;
}

.image-backdrop {
  height: 320px;
}

.btn-left {
  margin-top: unset;
}

.c-supporter-badge {
  margin-left: 6%;
}

.c-photo-container {
  display: flex;
}

.c-feature-song-title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-btn-follow {
  display: flex;
  justify-content: center;
}

.tap-mobile {
  display: block;
}

.tap-desktop {
  display: none;
}

.c-artist-stat {
  color: #9b9b9b;
}

.support-img {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .c-feature-song-title {
    max-width: 38vw;
  }

  .c-most-listened {
    margin-top: 1rem;
  }

  .c-artist-stat {
    margin-right: unset;
    padding-right: 0px;
    padding-left: 0px;
  }

  .btn-left {
    margin-top: 10px;
    margin-left: 10px;
  }

  .follow-group {
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 50px;
    width: unset;
    margin-bottom: 5%;
    padding-top: unset;
  }

  .c-profile-badges {
    padding: 0 15px 13px 15px !important;
    height: 13px;
    width: unset;
    font-size: 8px;
    line-height: 13px;
  }

  .c-artist-photo {
    height: 140px;
    width: 140px;
  }

  .more-btn {
    background-color: rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 30px;
    margin-top: unset;
    margin-right: 10px;
  }

  .btn-left {
    width: 35px;
    height: 35px;
  }

  .c-artist-header__name {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 2%;
  }

  .c-artist-header__strap-line {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 2%;
    margin-top: unset;
  }

  .c-btn-follow {
    height: 24px;
    width: 84px;
    font-size: 15px;
    padding: unset;
    padding-left: 20px !important;
    line-height: unset;
  }

  .c-comment-icon {
    width: 25px;
    margin-top: 0px;
    margin-right: 0.5rem;
  }

  .artist-profile {
    padding-left: unset;
    margin-top: unset;
  }

  .artist-container {
    padding: 30px;
    padding-top: 5px;
  }

  .photo-border {
    margin-top: -100px;
  }

  .stats-number {
    font-weight: 300;
    font-size: 25px;
    line-height: 21px;
  }

  .text-smaller {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
  }

  .stats-section {
    margin-top: 1em;
    padding-right: 15%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .stats-number {
    margin-bottom: 5px;
  }

  .social-icons {
    width: 20px;
    height: 20px;
  }

  .social-profiles {
    margin-top: 0px;
  }

  .c-last-artist-login,
  .c-artist-location {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-last-artist-login {
    margin-left: 14px;
  }

  .c-artist-location-logo,
  .c-last-artist-login-logo {
    height: 13px;
  }

  .l-social-and-data {
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .support-img {
    height: 35px;
    width: 111.73px;
    margin-top: 10px;
  }

  .c-artist-bio {
    font-size: 16px;
    line-height: 21px;
  }

  .chipin-support {
    margin-top: unset;
  }

  .chipin-support-title {
    font-size: 1.3em;
    margin-bottom: 15px;
  }

  .chipin-text {
    font-size: 0.8em;
  }

  .support-btn {
    padding-top: unset;
    padding-right: unset;
  }

  .section-title {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 21px;
  }

  .c-chipin-copyright__title {
    font-size: 0.7em;
    font-weight: 300;
    line-height: 21px;
  }

  .show-all-btn {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    margin-top: unset;
  }

  .c-most-popular-songs__song:not(:first-of-type) {
    margin-left: 16px;
  }

  .c-arrow-up-disk {
    height: 12px;
  }

  .popular-artist-song {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .popular-artist-name {
    display: none;
  }

  .song-more-btn {
    margin-left: unset;
    width: 80px;
    height: 80px;
  }

  .song-more-btn img {
    height: 30px;
  }

  .song-more-btn p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  .l-featured-songs {
    margin-top: 1rem;
  }

  .song-name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
  }

  .song-header > img {
    margin-right: 0.5rem;
    height: 20px;
  }

  .feature-div {
    display: flex;
    justify-content: space-between;
  }

  .more-icons {
    height: 20px;
  }

  .more-icons.heart {
    margin-right: 5px;
  }

  .likes-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 12px;
    margin-right: 0.7rem;
  }

  .btn-18 {
    margin-left: unset;
    margin-right: 0.5rem;
    font-size: 12px;
  }

  .song-btns {
    height: 16px;
  }

  .artist-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .song-recorder {
    width: 31px;
    height: 39px;
    margin-left: 32px;
  }

  .song-des {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .l-desktop-genres {
    display: none;
  }

  .l-mobile-genres {
    display: flex;
    flex-wrap: inherit;
    margin-right: 15px;
  }

  .l-mobile-genres > div {
    white-space: nowrap;
    display: contents;
  }

  .l-mobile-genres > div > img {
    margin-top: 3px;
  }

  .comment-des {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 1em;
  }

  .l-comments-section {
    padding-bottom: 1.5rem;
  }

  .c-artist-information > span,
  .recent-text,
  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p,
  .c-chipin-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .c-featured-song {
    min-width: unset;
  }

  .artist-page {
    height: 200px;
  }
  .c-collab-badge {
    margin-left: 0.2rem;
  }

  .displayed-on-mobile {
    display: block;
  }

  .displayed-on-desktop {
    display: none;
  }

  .l-social-and-data.displayed-on-desktop {
    display: none;
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1.3rem;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 22px;
    height: 22px;
  }

  .l-location-container {
    margin-top: 15px;
  }

  .image-backdrop {
    height: 200px;
  }

  .l-divisor-desc-support {
    margin-bottom: unset;
  }

  .btn-back {
    padding-top: unset;
    padding-left: unset;
  }
}

/* EXCEPTIONAL BREAKPOINT FOR VERY LOW RESOLUTION */
@media (max-width: 404px) {
  .stats-section {
    padding-right: unset;
  }

  .c-profile-badges {
    margin-left: 3%;
  }

  .artist-container {
    padding: 20px;
    padding-top: 5px;
  }

  .popular-artist-song {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1rem;
  }
}
@media (max-width: 576px) {
  .song-locked {
    position: absolute;
    right: 4px;
    top: -4px;
    width: 20px !important;
    z-index: 100;
  }
}

@media (min-width: 576px) {
  .l-desktop-genres {
    display: flex;
  }
  .support-img {
    margin-right: 1rem;
  }

  .tap-mobile {
    display: none;
  }

  .tap-desktop {
    display: block;
  }

  .l-mobile-genres {
    display: none;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    white-space: nowrap;
  }

  .song-name {
    margin-left: 17px;
  }
}

@media (min-width: 768px) {
  .displayed-on-mobile {
    display: block;
  }

  .l-hall-of-fame {
    margin-bottom: -9vh;
  }
  .c-artist-stat {
    text-align: center;
  }

  .stats-section {
    padding-left: 5%;
  }

  .displayed-on-desktop {
    display: none;
  }

  .c-artist-header__name {
    font-size: 48px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-artist-header__strap-line {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-artist-photo {
    width: 200px;
    height: 200px;
    border: 3px solid white;
  }

  .btn-left {
    margin-top: 5px;
    margin-left: 15px;
  }

  .c-profile-badges:first-child {
    margin-left: 0.7rem;
  }

  .stats-number {
    font-size: 42px;
    font-weight: 200;
    line-height: 21px;
  }

  .text-smaller {
    font-size: 16px;
    font-weight: 300;
    line-height: 18.75px;
  }

  .c-artist-stat {
    padding-right: unset;
  }

  .social-icons {
    width: 33.08px;
    height: 33.08px;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 35.08px;
    height: 35.08px;
  }

  .c-last-artist-login,
  .c-artist-location {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-last-artist-login {
    margin-left: 5px;
  }

  .c-artist-location-logo,
  .c-last-artist-login-logo {
    height: 30px;
  }

  .social-profiles {
    margin-top: unset;
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1rem;
  }

  .l-location-container {
    margin-top: 30px;
  }

  .l-social-and-data.displayed-on-desktop {
    display: none;
  }

  .c-artist-bio {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }

  .support-img {
    height: 60px;
    width: 193.17px;
  }

  .chipin-support {
    margin-top: unset;
  }

  .chipin-support-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
  }

  .chipin-text {
    font-size: 18px;
    line-height: 36px;
    font-weight: 300;
  }

  .section-title {
    font-size: 36px;
    line-height: 21px;
    font-weight: 200;
    margin-bottom: 1.5rem;
  }
  .c-chipin-copyright__title {
    font-size: 24px;
    font-weight: 200;
    line-height: 21px;
  }
  .c-most-listened {
    margin-top: 2rem;
  }

  .show-all-btn {
    font-weight: 300;
    font-size: 24px;
    line-height: 21px;

    margin-top: unset;
  }

  .popular-artist-song {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .l-popular-artist-song-description {
    margin-top: 0.1rem;
  }

  .c-featured-song {
    min-width: unset;
  }

  .song-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
    margin-left: 17px;
  }

  .artist-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 300;
  }

  .btn-18 {
    margin-right: 1.8rem;
  }

  .likes-count {
    margin-right: 0.6rem;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
  }

  .comment-des {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 1em;
  }

  .l-desktop-genres {
    white-space: nowrap;
    display: flex;
  }

  .c-btn-follow {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .c-chipin-description {
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
  }

  .recent-text {
    font-weight: 300;
    font-size: 32px;
    line-height: 21px;
  }

  .c-artist-information > span {
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
}

@media (min-width: 992px) {
  .displayed-on-mobile {
    display: block;
  }

  .displayed-on-desktop {
    display: none;
  }

  .l-social-and-data.displayed-on-desktop {
    display: none;
  }

  .c-artist-header__name {
    font-size: 60px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-artist-header__strap-line {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
  }

  .btn-dots {
    margin-top: 12px;
  }

  .c-btn-follow {
    width: 132px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.13px;
    padding-top: 3px;
  }

  .c-artist-stat {
    padding-right: 0px;
    padding-left: 0px;
  }

  .c-artist-photo {
    width: 200px;
    height: 200px;
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .social-icons {
    width: 33.08px;
    height: 33.08px;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 35.08px;
    height: 35.08px;
  }

  .displayed-on-mobile.social-profiles {
    margin-top: unset;
    width: 16rem;
    justify-content: space-between;
    display: flex;
  }

  .l-location-container {
    margin-top: 20px;
  }

  .show-all-btn {
    font-size: 24px;
    font-weight: 300;
    line-height: 21px;
  }

  .popular-artist-name {
    line-height: 24px;
  }

  .song-recorder {
    width: 52px;
    height: 70px;
  }

  .song-name {
    line-height: 21px;
    margin-left: 16px;
  }

  .artist-name {
    font-weight: 100;
    font-size: 24px;
    line-height: 21px;
  }

  .btn-18 {
    margin-right: 2rem;
    width: 29px;
    padding-left: 6px;
  }

  .likes-count {
    margin-right: 0.6rem;
  }

  .l-desktop-genres {
    white-space: nowrap;
    display: flex;
    margin-bottom: 4rem;
  }

  .genre-box {
    height: 35px;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
  }
}

@media (min-width: 1200px) {
  .displayed-on-mobile {
    display: none;
  }

  .displayed-on-desktop {
    display: block;
  }

  .follow-group {
    max-width: 1060px;
    padding-right: 0px;
  }

  .l-social-and-data.displayed-on-desktop {
    display: flex;
  }

  .c-comment-icon {
    display: none;
  }

  .c-artist-photo {
    width: 250px;
    height: 250px;
  }

  .c-artist-header__name {
    font-size: 60px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-artist-header__strap-line {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
  }

  .btn-dots {
    margin-top: 12px;
  }

  .c-btn-follow {
    width: 132px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.13px;
    padding-top: 3px;
  }

  .c-artist-stat {
    padding-right: 0px;
    padding-left: 0px;
  }

  .btn-left {
    margin-top: 2px;
  }

  .social-profiles {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    margin-bottom: 1.2rem;
  }

  .c-last-artist-login,
  .c-artist-location {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .l-divisor-desc-support {
    margin-top: 20px;
  }

  .song-more-btn {
    width: 150px;
    height: 150px;
  }

  .c-featured-song {
    max-width: 50%;
  }

  .song-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
    margin-left: 17px;
  }

  .btn-18 {
    margin-right: 2rem;
  }

  .likes-count {
    margin-right: 0.6rem;
  }

  .artist-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 200;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
  }

  .support-img {
    height: 80px;
    width: 260px;
  }

  .chipin-support-title {
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
  }

  .chipin-text {
    font-weight: 300;
    font-size: 24px;
    line-height: 48px;
  }

  .l-most-popular-songs {
    margin-top: 30px;
  }

  .song-recorder {
    height: 75px;
    width: 60px;
  }

  .social-icons {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 450px) {
  .c-photo-container {
    padding-left: unset;
  }

  .c-feature-song-title {
    max-width: 36vw;
  }
}

@media (max-width: 320px) {
  .artist-profile {
    padding-left: 10px;
  }

  .c-profile-badges {
    padding: 0px 6px 12px 6px !important;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 10px;
  }

  .chipin-support-title {
    font-size: 0.9em;
    font-weight: 300 !important;
    margin-bottom: 15px;
  }
}
</style>
