<template>
  <b-row class="py-3 positioning profile-section pb-0 pb-md-3">
    <b-col>
      <h3 class="text-light mb-3 font-weight-lighter tab-title section-heading">
        Genre
        <!--<b-button variant="link" class="float-right text-light show-all-btn"
          >Show All</b-button
        >-->
      </h3>
      <div class="border-gradient"></div>

      <ul v-if="song.genre" class="list-inline overflow-hidden">

        <b-button size="lg" variant="outline-light" class="m-2 px-3 genre-box">
          {{ song.genre.name }}
        </b-button>
        <b-button
          v-if="song.sub_genre"
          size="lg"
          variant="outline-light"
          class="m-2 px-3 genre-box"
        >
          {{ song.sub_genre.name }}
        </b-button>
        <b-button
          v-if="song.sub_sub_genre"
          size="lg"
          variant="outline-light"
          class="m-2 px-3 genre-box"
        >
          {{ song.sub_sub_genre.name }}
        </b-button>

      </ul>

      <p v-else class="comment-des py-2 pb-3 mb-0">Awaiting Genres</p>

    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SongGenreSection",
  props: ["section", "song"],
};
</script>

<style>
.border-gradient {
  border-top: 2px solid #fff;
  border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0.1)) 1 40%;
  border-image-slice: 1;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 100%;
  margin-bottom: 2em;
  margin-top: 0.8em;
}

.genre-box {
  height: 20px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 10px;
  padding: 0 40px 1px 40px;
}

@media (min-width: 576px) {
  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
  }
}
</style>
